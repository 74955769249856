import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Suspense, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Spin from "./utils/Spin.js";
import { useStateValue } from "./State/Stateprovider";

//const AppDownloadPopup = React.lazy(() => import("./Components/AppDownloadPopup"));
const EmailSettings = React.lazy(() => import("./Pages/Users/EmailSettings.jsx"));
const Global = React.lazy(() => import("./Pages/Users/Global.js"));
const Dashboard = React.lazy(() => import("./Pages/Users/Dashboard.js"));
const Status = React.lazy(() => import("./Pages/Users/Status.js"));
const Profile = React.lazy(() => import("./Pages/Users/Profile.js"));
const Subscription = React.lazy(() => import("./Pages/Users/Subscription.js"));
const CardDetails = React.lazy(() => import("./Components/CardDetails.js"));
const InvoiceWrapper = React.lazy(() => import("./Pages/Users/InvoiceWraper.js"));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_P_KEY);

const UserRoutes = () => {
  const [{ user }] = useStateValue();
  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const preloadPopup = async () => {
  //     await import("./Components/AppDownloadPopup"); // Preload modal component
  //   };
  //   preloadPopup();
  // }, []);
  
  // useEffect(() => {
  //   if (user?.role === "user") {
  //     const dontShow = localStorage.getItem("dontShowAppDownload");
  //     if (dontShow !== "true") {
  //       setShowPopup(true);
  //     }
  //   }
  // }, [user]);

  
  //   const handleDashboardNavigation = () => {
  //   const dontShow = localStorage.getItem("dontShowAppDownload");
  //   if (dontShow !== "true") {
  //     setShowPopup(true);
  //   }
  // };

  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <Switch>
        <Elements stripe={stripePromise}>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/settings" component={Global} />
          <Route exact path="/status" component={Status} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/invoices" component={InvoiceWrapper} />
          <Route exact path="/emailSettings" component={EmailSettings} />
          <Route exact path="/cardDetails" component={CardDetails} />
          <Redirect from="/" to="/dashboard" />
        </Elements>
      </Switch>
      {/* <AppDownloadPopup show={showPopup} handleClose={() => setShowPopup(false)} /> */}
    </Suspense>
  );
};

export default UserRoutes;